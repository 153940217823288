export function storageAvailable(StorageParam) {
  let Storage = StorageParam;
  const mod = 'dummy_ls_test';
  try {
    Storage = Storage || localStorage;
    Storage.setItem(mod, mod);
    Storage.removeItem(mod);
    return true;
  } catch {
    return false;
  }
}

export function storageGet(name, StorageParam) {
  let value;
  let Storage = StorageParam;
  try {
    Storage = Storage || localStorage;
    value = Storage.getItem(name);
  } catch {
    return false;
  }
  return value;
}

export function storageSet(name, value, StorageParam) {
  let Storage = StorageParam;
  try {
    Storage = Storage || localStorage;
    Storage.setItem(name, value);
  } catch {
    // Error is ignored, value remains null
  }
}
